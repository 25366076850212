
export default function Toast({ message, close }) {
    return (
        <div className="z-50 fixed left-0 bottom-0 w-full pb-10 px-4">
            <div className="flex items-center p-4 px-9 bg-black max-w-max mx-auto rounded-full">
                <span className="mr-4 text-white">{message}</span>
                <a 
                    className="cursor-pointer"
                    onClick={() => close()}
                >
                    <img src="/img/close-icon.png" alt="" />
                </a>
            </div>
        </div>
    );
}