export default function ContentHeading({ greenText, bigTitle, smallWhiteText }) {
    return (
        <div className="mb-20 md:max-w-2xl text-center mx-auto">
            <span className="inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter">
                { greenText }
            </span>
            <h2 className="font-heading mb-6 text-3xl lg:text-7xl text-white tracking-7xl lg:tracking-8xl">
                { bigTitle }
            </h2>
            { smallWhiteText !== undefined &&
                <p className="text-gray-300 md:max-w-md mx-auto">
                    { smallWhiteText }
                </p>
            }
        </div>
    );
}