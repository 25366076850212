import ContentHeading from "./ContentHeading";
import Button from "./Button";
import { useRef, useState, useEffect, useCallback } from "react";

export default function ServicesContent({}) {
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();

    const observedDiv = useRef(null);
    useEffect(() => {
        if (!observedDiv.current) {
          return;
        }
    
        const resizeObserver = new ResizeObserver(() => {
          if(observedDiv.current.offsetWidth !== width) {
            setWidth(observedDiv.current.offsetWidth); 
          }
          if(observedDiv.current.offsetHeight !== height) {
            setHeight(observedDiv.current.offsetHeight);
          }
        });
        
        resizeObserver.observe(observedDiv.current);
    
        return function cleanup() {
          resizeObserver.disconnect();
        }
      },
      [observedDiv.current]);
    

    const serviceCards = [
        {
            title: "Web Application Development",
            body: "I offer personalized web application development solutions tailored to your business needs. From concept to launch, I collaborate closely to understand your requirements and deliver intuitive, scalable applications. With a focus on efficiency and quality, I utilize modern technologies to ensure seamless user experiences across devices. Whether you need an e-commerce platform, CRM system, or unique business tool, I provide end-to-end development, ensuring your application is secure and future-ready. Partner with me to transform your ideas into functional, impactful digital solutions that drive your business forward."
        },
        {
            title: "API Integration",
            body: "I offer tailored solutions to enhance your digital infrastructure. With expertise in integrating APIs across platforms, I streamline processes, automate tasks, and improve data flow seamlessly. From initial consultation to implementation and ongoing support, I ensure efficient and secure integrations that align with your business goals. Whether you require payment gateway integration, CRM synchronization, or system connectivity, I deliver robust solutions that optimize performance and scalability. Partner with me to leverage the power of integrated APIs and elevate your business operations effectively"
        },
        {
            title: "Python Development",
            body: "Leveraging Python's versatility and efficiency, I create bespoke solutions tailored to your specific needs. Whether you need automated workflows, data extraction from websites, or customized scripts, I deliver robust applications that enhance productivity and efficiency. With a commitment to quality and precision, I ensure seamless integration and reliable performance. Partner with me to harness the power of Python for automating tasks and extracting valuable insights, optimizing your operations for maximum efficiency and competitiveness."
        },
        {
            title: "Security Solutions",
            body: "I provide comprehensive solutions including Let's Encrypt SSL certificates with automated renewals, ensuring continuous HTTPS protection for your websites. Additionally, I offer OpenPGP integration services encompassing key generation, management, encryption, and decryption using public and private key pairs. Whether securing sensitive data or ensuring encrypted communication channels, I prioritize robust security measures tailored to your business needs. With a focus on reliability and confidentiality, I deliver seamless integration and ongoing support, empowering your applications with state-of-the-art cryptographic solutions. Partner with me to fortify your digital assets and safeguard your communications effectively."
        }
    ]

    const renderCards = _ => {
        return serviceCards.map(card => {
            return (
                <div className="w-full lg:w-1/2 p-4">
                    <div className="relative h-full p-8 md:p-12 bg-gradient-radial-dark overflow-hidden border border-gray-900 border-opacity-30 rounded-5xl">
                        <div className="relative z-10">
                            <h3 className="mb-8 text-3xl text-white tracking-3xl">{card.title}</h3>
                            <p className="text-gray-300">
                                {card.body}
                            </p>
                        </div>
                        <img className="absolute bottom-0 right-0" src="/img/shadow.png" alt />
                    </div>
                </div>
            )
        });
    }

    return (
            <section className="py-20 overflow-hidden ">
                <div className="container px-4 mx-auto">
                    <ContentHeading
                        greenText="Web Development and Programming Services"
                        bigTitle="Services"
                        smallWhiteText="Let's transform your vision into a dynamic online reality"
                    />
                    
                    <div className="flex flex-wrap -m-4 mb-10">
                        
                        {renderCards()}

                        <Button style="filled" href="/contact">
                            Request Quote
                        </Button>
                    </div>
                </div>
            </section>
        );
}