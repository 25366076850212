import ContentHeading from "./ContentHeading";

export default function TechnologiesContent({}) {
    const blurbs = [
        {
            top: "BackEnd",
            bottom: "PHP, Java, Node.js, Python"
        },{
            top: "Frameworks",
            bottom: "Laravel, Wordpress, OpenCart"
        },{
            top: "Relational Databases",
            bottom: "MySQL, MSSQL, MariaDB, SQLite, Postgres"
        },{
            top: "Frontend",
            bottom: "React, Vue, Javascript"
        },{
            top: "Web Scraping",
            bottom: "Python, Selenium, Selenium Grid, Cloudfare/Bot-detection bypass"
        },{
            top: "Security",
            bottom: "OpenPGP Key Generation, Management, Encryption, Decryption, LetsEncrypt SSL Certificate Automated Renewal"
        }
    ]

    return (
        <section className="py-20 overflow-hidden">
            <div className="container px-4 mx-auto">
                <ContentHeading 
                    greenText="Full Stack Development"
                    bigTitle="Technologies/Languages"
                />
                <div className="flex flex-wrap -m-8">
                    <div className="w-full md:w-7/12 p-8">
                        <div className="flex flex-wrap -m-6">
                            {
                                blurbs.map(blurb => {
                                    return (
                                        <div className="w-full p-6">
                                            <div className="pb-6 border-b border-gray-900">
                                                <div className="flex flex-wrap items-center -m-2 mb-2">
                                                    <div className="w-auto p-2">
                                                        <span className="text-sm text-white font-medium tracking-tighter">{ blurb.top }</span>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap -m-2">
                                                    <div className="flex-1 p-2">
                                                        <h3 className="font-heading text-2xl text-white tracking-2xl">{ blurb.bottom }</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="w-full md:w-5/12 p-8">
                        <div className="max-w-max mx-auto md:mr-0 overflow-hidden rounded-3xl">
                            <img className="w-full transform hover:scale-125 transition duration-1000" src="/img/code.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}