import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from '../components/Button';
import Toast from '../components/Toast';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Login({ data }) {
  const [showToast, setShowToast] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if(data && data.message) {
        setShowToast(true);
    }
  },[data]);

  const handleChange = e => {
    setValues({...values, [e.target.id]: e.target.value});
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className="py-20 overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="relative px-7 pt-10 pb-8 bg-gray-900 bg-opacity-30 max-w-lg mx-auto rounded-5xl">
            <div className="text-center mx-auto">
              <a className="mb-9 flex justify-center" href="/">
                <img src="/img/logo-no-background-small.png" alt=""/>
              </a>
              <h3 className="mb-10 text-3xl font-medium text-white tracking-5xl">Log in to your account</h3>
              <form method="post" action="/login">
            <div className="mb-2 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
              <input 
                className="pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent" 
                type="text" 
                placeholder="Enter your email"
                id="email" 
                name="email"
                value={values.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-6 relative border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
              <img className="absolute right-7 top-1/2 transform -translate-y-1/2" src="/img/eyeslash.svg" alt />
                <input 
                  className="pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent" 
                  type="password" 
                  placeholder="Password" 
                  id="password" 
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
              </div>
              <button 
                className={`w-full mb-4 inline-block px-8 py-5 text-black font-medium rounded-full bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 transition duration-300 mt-5 m-auto`}
                type="submit"
              >
                Log In
              </button>
              <a className="mb-10 inline-block text-sm text-gray-300 underline" href="/forgotpassword">Forgot password?</a>
              </form>
            </div>
          </div>
        </div>
      </section>
      {
        showToast && <Toast message={data.message} close={() => setShowToast(false)}/>
      }
    </React.Fragment>
  );
}

