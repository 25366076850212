import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import NavMenu from '../components/NavMenu';
import AboutContent from '../components/AboutContent';

const meta = {
  title: 'About',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function About() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <NavMenu activePage="about"/>
      <AboutContent />
    </React.Fragment>
  );
}

