import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './css/app.css';
import IndexPage from './pages/Index.jsx';
import Technologies from './pages/Technologies.jsx';
import AboutPage from './pages/About.jsx';
import ContactPage from './pages/Contact.jsx';
import LoginPage from './pages/Login.jsx';
import ForgotPasswordPage from './pages/Forgot_password.jsx';

const containers = ['services', 'technologies', 'about', 'contact', 'login', 'forgotpassword'];

const element = containers.filter(container => {
    if(document.getElementById(container)) {
        return true;
    }
});

const container = document.getElementById(element);
const root = createRoot(container);
let data = document.getElementById('data').textContent.trim();

if(data && data.textContent!=='') {
    data = JSON.parse(data);
}

const handleRender = _ => {
    switch(element[0]) {
        case 'services':
            return <IndexPage />;
        case 'technologies':
            return <Technologies />;
        case 'about':
            return <AboutPage />;
        case 'contact':
            return <ContactPage data={data} />;
        case 'login':
            return <LoginPage data={data} />;
        case 'forgotpassword':
            return <ForgotPasswordPage data={data}/>;
        default:
            return <>Ooops...The path you accessed does not exist. Please check your URL and try again!</>
    }
}

root.render(
    <React.StrictMode>
        <BrowserRouter>
            { handleRender() }
        </BrowserRouter>
    </React.StrictMode>
);
