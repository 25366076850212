export default function Button({ style, children, href=undefined, onClick=undefined, className="" }) {
    switch(style) {
        case 'filled':
            return (
                <a 
                    className={`${className} inline-block px-8 py-5 text-black font-medium rounded-full bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 transition duration-300 mt-5 m-auto`}
                    href={href}
                    onClick={onClick}
                >
                    { children }
                </a>
            )
        default:
            return (
                <a 
                    className={`${className} inline-block px-8 py-4 text-white hover:text-black font-medium tracking-tighter hover:bg-green-400 border-2 border-white focus:border-green-400 focus:border-opacity-40 hover:border-green-400 focus:ring-4 focus:ring-green-400 focus:ring-opacity-40 rounded-full transition duration-300`}
                    href={href}
                    onClick={onClick}
                >
                    { children }
                </a>
            )
    }
    
}