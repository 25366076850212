import { useState, useEffect } from "react";
import Toast from "./Toast";

export default function ContactContent({ data }) {
    const [showToast, setShowToast] = useState(false);
    const [values, setValues] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        company: '',
        help: '',
        message: ''
    });

    useEffect(() => {
        if(data && data.message) {
            setShowToast(true);
        }
        if(data && data.old_input) {
            setValues(data.old_input)
        }
    },[data]);

    const handleChange = e => {
        setValues({...values, [e.target.id]: e.target.value});
    }

    return (
        <section className="py-24 overflow-hidden">
            <div className="container px-4 mx-auto">
                <div className="flex flex-wrap -m-8">
                    <div className="w-full md:w-1/2 p-8">
                        <div className="">
                            <span className="inline-block w-full text-center md:text-left mb-4 text-sm text-green-400 font-medium tracking-tighter">
                                Let's Work Together
                            </span>
                            <h2 className="font-heading mb-8 text-center md:text-left text-3xl lg:text-7xl text-white tracking-8xl lg:tracking-8xl">
                                Contact
                            </h2>
                            <p className="mb-8 text-gray-300">
                                Let me help you become even greater at what you do. Fill out the following form and I will get back to you in the next 24 hours.
                            </p>
                            <form method="post" action="/contact">
                                <div className="mb-4 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
                                    <input 
                                        className="pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent" 
                                        type="text" 
                                        placeholder="First name"
                                        id="first_name"
                                        name="first_name"
                                        value={values.first_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
                                    <input 
                                        className="pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent" 
                                        type="text" 
                                        placeholder="Last Name" 
                                        id="last_name"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="hidden mb-4 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
                                    <input 
                                        className="pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent" 
                                        type="text" 
                                        placeholder="Phone Number" 
                                        id="phone_number"
                                        name="phone_number"
                                        value={values.phone_number}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
                                    <input 
                                        className="pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent" 
                                        type="text" 
                                        placeholder="E-mail"
                                        id="email"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
                                    <input 
                                        className="pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent" 
                                        type="text" 
                                        placeholder="Company" 
                                        id="company"
                                        name="company"
                                        value={values.company}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
                                    <div className="relative overflow-hidden rounded-3xl">
                                        <select 
                                            className="appearance-none pl-6 pr-16 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent" 
                                            type="text" 
                                            placeholder="Company"
                                            value={values.help}
                                            id="help"
                                            name="help"
                                            onChange={handleChange}
                                        >
                                            <option hidden value={null}>What can I help you with?</option>
                                            <option value="request a quote">I'd like to request a quote</option>
                                            <option value="comments/feedback">Comments/Feedback</option>
                                        </select>
                                        <svg className="absolute right-8 top-1/2 transform -translate-y-1/2" width={16} height={9} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.6004 1.4585L9.16706 6.89183C8.52539 7.5335 7.47539 7.5335 6.83372 6.89183L1.40039 1.4585" stroke="white" strokeWidth="1.66667" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                                <textarea 
                                    className="mb-3 w-full px-6 py-4 text-gray-300 placeholder-gray-300 bg-transparent border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl resize-none" 
                                    placeholder="Message" 
                                    rows={9} 
                                    value={values.message}
                                    id="message"
                                    name="message"
                                    onChange={handleChange}
                                />
                                <div className="flex flex-wrap justify-end">
                                    <div className="w-auto">
                                        <button 
                                            type="submit"
                                            className="cursor-pointer inline-block px-8 py-5 text-black font-medium rounded-full bg-green-400 hover:bg-green-500 focus:ring-4 focus:ring-green-500 focus:ring-opacity-40 transition duration-300 mt-5 m-auto"
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 p-8">
                        <img className="rounded-5xl mx-auto md:mr-0" src="/img/googlemap.png" alt="" />
                    </div>
                </div>
            </div>
            {
                showToast && <Toast message={data.message} close={() => setShowToast(false)}/>
            }
        </section>
    );
}