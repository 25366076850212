import Button from "./Button";

export default function AboutContent({}) {
    return (
        <section className="pt-20 pb-24 lg:pb-32 overflow-hidden ">
            <div className="text-center mb-20">
                <span className="inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter">Professional and Experienced</span>
                <h2 className="font-heading mb-6 text-3xl lg:text-7xl text-white tracking-8xl md:max-w-md mx-auto">About</h2>
            </div>
            <div className="container px-4 mx-auto">
                <div className="relative text-center md:max-w-4xl mx-auto">
                    <p className="mb-8 pl-4 pr-4 max-md:text-xl text-2xl text-white tracking-tight leading-normal">
                        As a professional developer committed to transforming challenges into opportunities, I bring a unique perspective shaped by resilience and dedication. With a passion for crafting intuitive and impactful digital solutions, I leverage my technical expertise to create websites that exceed expectations. Emerging from a transformative journey, I am driven by a deep sense of purpose to deliver quality and innovation in every project. Dedicated to professionalism and continuous growth, I strive to empower businesses with cutting-edge web development solutions that inspire confidence and drive success.
                    </p>
                    <Button style="filled" href="/contact">
                        Contact
                    </Button>
                    <img className="absolute top-8 -left-20" src="/img/star.svg" alt="" />
                    <img className="absolute top-48 -right-20" src="/img/star-light.svg" alt=""/>
                </div>
            </div>
        </section>
    );
}