import { useState } from "react";
import Button from "./Button";

export default function NavMenu({ activePage }) {
    const [menuOpen, setMenuOpen] = useState(false);

    const renderNavUl = size => {
        return (
            <>
                <li className={
                    size == 'big' ?
                        `mr-12 ${activePage=='services' ? 'text-green-400' : 'text-white'} font-medium hover:text-opacity-90 tracking-tighter` :
                            "mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter"
                    }
                >
                    <a href="/">Services</a>
                </li>
                <li className={
                    size == 'big' ?
                        `mr-12 ${activePage=='technologies' ? 'text-green-400' : 'text-white'} font-medium hover:text-opacity-90 tracking-tighter` :
                            "mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter"
                    }
                >
                    <a href="/technologies">Technologies/Languages</a>
                </li>
                <li className={
                    size == 'big' ?
                        `mr-12 ${activePage=='about' ? 'text-green-400' : 'text-white'} font-medium hover:text-opacity-90 tracking-tighter` :
                            "mb-8 text-white font-medium hover:text-opacity-90 tracking-tighter"
                    }
                >
                    <a href="/about">About</a>
                </li>
                <li className={
                    size == 'big' ?
                        `${activePage=='contact' ? 'text-green-400' : 'text-white'} font-medium hover:text-opacity-90 tracking-tighter` :
                            "text-white font-medium hover:text-opacity-90 tracking-tighter"
                    }
                >
                    <a href="/contact">Contact</a>
                </li>
            </>
        );
    }

    const renderLoginButton = size => {
        return size == 'big' ?
            <Button href="/login">Customer Login</Button> :
                <Button href="/login" className="text-center">Customer Login</Button>
    }

    return (
        <>
        <section>
            <div className="container px-4 mx-auto">
                <div className="flex items-center justify-between pt-5 pb-2.5 -m-2">
                <div className="w-auto p-2">
                    <div className="flex flex-wrap items-center">
                    <div className="w-auto">
                        <a className="relative z-10 inline-block" href="/">
                            <img style={{height: '24px', width: '150px'}} src="/img/logo-no-background-small.png" alt="" />
                        </a>
                    </div>
                    </div>
                </div>
                <div className="w-auto p-2">
                    <div className="flex flex-wrap items-center">
                        <div className="w-auto hidden lg:block">
                            <ul className="flex items-center mr-12">
                                { renderNavUl('big') }
                            </ul>
                        </div>
                        <div className="w-auto hidden lg:block">
                            <div className="inline-block">
                                { renderLoginButton('big') }
                            </div>
                        </div>
                        <div className="w-auto lg:hidden">
                            <button 
                                className="relative z-10 inline-block"
                                onClick={() => setMenuOpen(true)}
                            >
                                <svg className="text-green-500" width={51} height={51} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width={56} height={56} rx={28} fill="currentColor" />
                                    <path d="M37 32H19M37 24H19" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className={`${!menuOpen ? 'hidden' : ''} fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50`}>
                <div className="fixed inset-0 bg-black opacity-60" />
                <nav className="relative z-10 px-9 pt-8 h-full bg-black overflow-y-auto">
                <div className="flex flex-wrap justify-between h-full">
                    <div className="w-full">
                    <div className="flex items-center justify-between -m-2">
                        <div className="w-auto p-2">
                        <a className="inline-block" href="/">
                            <img style={{height: '24px', width: '150px'}} src="/img/logo-no-background-small.png" alt="" />
                        </a>
                        </div>
                        <div className="w-auto p-2">
                        <button 
                            className="inline-block text-white"
                            onClick={() => setMenuOpen(false)}
                        >
                            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        </div>
                    </div>
                    </div>
                    <div className="flex flex-col justify-center py-16 w-full">
                    <ul>
                        { renderNavUl('small') }
                    </ul>
                    </div>
                    <div className="flex flex-col justify-end w-full pb-8">
                        { renderLoginButton('small') }
                    </div>
                </div>
                </nav>
            </div>
            </section>
        </>
    )
}